'use client'

import Minus from "../../../../public/svg/minus.svg";
import Plus from "../../../../public/svg/plus.svg";
import { useState } from "react";

export interface LandingTravaillerAvecNousProps {
    title: string;
    content: string;
  }

function LandingTravaillerAvecNous({ sections }: { sections: LandingTravaillerAvecNousProps[] }){

  const [openSection, setOpenSection] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    setOpenSection(prevIndex => (prevIndex === index ? null : index));
  };

  return (
      <>
        {sections.map((section, index) => (
          element(section, index, toggleSection, openSection)
        ))}
      </>
  );
}
export default LandingTravaillerAvecNous;
function element({
  title,
  content
}:LandingTravaillerAvecNousProps, index:number , toggleSection:CallableFunction, openSection: number | null){
  return(
    <div key={index}>
      <div className='mt-3 mb-3 bg-foreground h-px md:w-3/4 m-0'/>
            <button
              className="flex items-center mb-2 w-full"
              onClick={() => toggleSection(index)}
            >
              <h2 className="text-lg text-left flex-grow md:w-8/12 md:flex-none">{title}</h2>
              <div className="flex justify-end md:flex-none">
              {openSection !== index ? <Plus stroke="var(--foreground-color)" height="25px" width="25px"/> : <Minus stroke="var(--foreground-color)" height="25px" width="25px"/>}
              </div>
              
            </button>
            <div className={`overflow-hidden transition-[max-height] ease-in-out ${openSection === index ? 'max-h-40' : 'max-h-0'}`}>
              <p className="md:w-3/4 text-foreground-900">{content}</p>
            </div>
          </div>
  )

}